import styled from 'styled-components';
import {
	mediaQuery,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../../utils/helpers';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	${mediaQuery.tabletL`
        display: flex;
    `}
	${mediaQuery.mobileXL`
        flex-direction: column;
    `}
`;

export const Left = styled.div`
	padding: 0 15px;
	${mediaQuery.mobileXL`
        padding: 11px 15px;
    `}
`;
export const Right = styled.div`
	padding: 0 15px;
	.contentWrapper {
		max-width: 400px;
		margin: 0 auto;
		font-family: ${setFontFamily.book};
		font-size: ${setFontSize.tiny};
		line-height: 1.5;
		h4,
		p {
			margin: 0;
		}
		h4 {
			font-family: ${setFontFamily.bold};
			font-size: ${setFontSize.verySmall};
			font-weight: ${setFontWeight.bold};
			${props => props.theme.fontNormal};
			margin: 10px 0;
			line-height: 1.4;
		}
		${mediaQuery.laptopXL`
            font-size: ${setFontSize.mediumTiny};
        `}
		${mediaQuery.tabletL`
            font-size: ${setFontSize.veryTiny};
        `}
        ${mediaQuery.mobileXL`
            h4{
                font-size: ${setFontSize.mediumTiny};
            }
        `}
	}
`;

export const pBetween = styled.div`
	display: block;
	height: 40px;
	${mediaQuery.mobileXL`
        height: 10px;
    `}
`;
