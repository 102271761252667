import React from 'react';
import styled from 'styled-components';
import { setColors, mediaQuery } from '../../../../utils/helpers';

const HR = styled.div`
	border-top: 1px solid ${setColors.warmGray};
	border-color: ${setColors.gainsBoro};
	margin: ${props => props.margin && `${props.margin}px 0`};

	${mediaQuery.mobileXL`
        margin: ${props => props.margin === '50' && `30px 0`};
    `}
`;

const SeparatorHR = ({ margin }) => {
	return <HR margin={margin} />;
};

export default SeparatorHR;
