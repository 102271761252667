import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { setColors } from '../../utils/helpers';
import Layout from '../components/global/layout/Layout';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import InfoBox from '../components/global/info-box/InfoBox';
import SectionTextBox from '../components/global/section-textbox/SectionTextBox';
import { ImageHolder } from '../components/home-page/about-chatbots/styles';
import Snippet from '../components/global/snippet/Snippet';
import Padding from '../components/global/Padding/Padding';
import SEO from '../components/global/seo';
import CTA from '../components/global/CTA/CTA';
import FeatureBox from '../components/global/FeatureBox';
import CustomerStory from '../components/global/CustomerStory';
import FeatureSnippetTwoHolder from '../components/comparison-pages/FeatureSnippetTwoHolder';
import * as S from '../components/comparison-pages/intercom/style';

const comparisonTemplate = ({ data }) => {
	const {
		heroTitle,
		heroSubtitle,
		heroButtonText,
		heroButtonLink,
		heroImage,
		section1Title,
		section1Images,
		section2Title,
		section2Text,
		section2Image,
		section2Content,
		customerStoryImage,
		customerStoryLogo,
		customerStoryText,
		customerName,
		customerTitle,
		customerSlug,
		section3Title,
		section3Text,
		section3Image,
		section3Content,
		section4Title,
		section4Text,
		section4Image,
		section5Title,
		section5Images,
		ctaTitle,
		ctaSubtitle,
		seoTitle,
		seoDescription,
		seoArticle,
		seoImage,
	} = data.contentfulVsPageTemplate;
	return (
		<Layout>
			<WrapperOne>
				<S.HeroWrapper>
					<InfoBox
						image={heroImage}
						title={heroTitle}
						subtitle={heroSubtitle}
						hideImage="true"
						buttonText={heroButtonText}
						buttonLink={heroButtonLink}
						isHeroHeader
					/>
				</S.HeroWrapper>
			</WrapperOne>
			<S.SectionWrapper bgColor={setColors.warmGray}>
				<WrapperOne>
					<S.ContentWrapper>
						<div className="feature-header">
							<SectionTextBox title={section1Title} setTitleHtml="true" />
						</div>
						<div className="pt-20"></div>
						<div className="image-holder-wrapper">
							<ImageHolder>
								{section1Images.map(info => (
									<Snippet
										url={info.file.url}
										title={info.title}
										subtitle={info.description}
										key={info.title}
									/>
								))}
							</ImageHolder>
						</div>
					</S.ContentWrapper>
				</WrapperOne>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<WrapperOne>
					<Padding padding="70" />
					<div className="section-header">
						<SectionTextBox
							title={section2Title}
							richTextContent={section2Text}
						/>
					</div>
					<S.FeatureBoxWrapper padding="50">
						<FeatureBox image={section2Image} content={section2Content} />
					</S.FeatureBoxWrapper>
				</WrapperOne>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<WrapperOne>
					<Padding padding="100" />
					<CustomerStory
						customerStoryImage={customerStoryImage}
						customerStoryLogo={customerStoryLogo}
						customerStoryText={customerStoryText}
						customerName={customerName}
						customerTitle={customerTitle}
						cusomterSlug={customerSlug}
					/>
				</WrapperOne>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<WrapperOne>
					<Padding padding="100" />
					<div className="section-header">
						<SectionTextBox
							title={section3Title}
							richTextContent={section3Text}
						/>
					</div>
					<S.FeatureBoxWrapper>
						<FeatureBox image={section3Image} content={section3Content} />
					</S.FeatureBoxWrapper>
					<Padding padding="100" />
				</WrapperOne>
			</S.SectionWrapper>
			<S.SectionWrapper bgColor={setColors.warmGray}>
				<WrapperOne wrapperClass="disable-grid-mobile">
					<S.ContentWrapper>
						<div className="feature-header">
							<SectionTextBox
								title={section4Title}
								richTextContent={section4Text}
							/>
						</div>
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							{section4Image.file ? (
								section4Image.file.contentType === 'image/svg+xml' ? (
									<img
										src={section4Image.file.url}
										style={{ width: '911px', maxWidth: '100%' }}
										alt={section4Image.title}
									/>
								) : (
									<Img
										fluid={section4Image.fluid}
										alt={section4Image.title}
										imgStyle={{ objectFit: 'contain' }}
										style={{ width: '911px', maxWidth: '100%' }}
									/>
								)
							) : (
								<Img
									fluid={section4Image.fluid}
									alt={section4Image.title}
									imgStyle={{ objectFit: 'contain' }}
									style={{ width: '911px', maxWidth: '100%' }}
								/>
							)}
						</div>
					</S.ContentWrapper>
				</WrapperOne>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<WrapperOne>
					<Padding padding="70" />
					<div className="section-header">
						<SectionTextBox title={section5Title} />
					</div>
					<Padding padding="100" />
					<FeatureSnippetTwoHolder sectionImages={section5Images} />
				</WrapperOne>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<WrapperOne>
					<div className="cta-wrapper">
						<CTA title={ctaTitle} subtitle={ctaSubtitle} />
					</div>
				</WrapperOne>
			</S.SectionWrapper>
			<SEO
				title={seoTitle}
				description={seoDescription}
				article={seoArticle}
				image={seoImage}
			/>
		</Layout>
	);
};

export const pageQuery = graphql`
	query($slug: String) {
		contentfulVsPageTemplate(slug: { eq: $slug }) {
			heroTitle
			heroSubtitle
			heroButtonText
			heroButtonLink
			heroImage {
				fluid {
					...GatsbyContentfulFluid
				}
				file {
					url
					contentType
				}
				title
			}
			section1Title
			section1Images {
				file {
					url
				}
				fixed(width: 100, quality: 100) {
					...GatsbyContentfulFixed
				}
				description
				title
			}
			section2Title
			section2Text {
				raw
			}
			section2Image {
				fluid {
					...GatsbyContentfulFluid
				}
				file {
					contentType
					url
				}
				title
			}
			section2Content {
				title
				content
			}
			customerStoryImage {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			customerStoryLogo {
				fixed(width: 125) {
					...GatsbyContentfulFixed
				}
				title
			}
			customerStoryText
			customerName
			customerTitle
			customerSlug
			section3Title
			section3Text {
				raw
			}
			section3Image {
				fluid {
					...GatsbyContentfulFluid
				}
				file {
					contentType
					url
				}
				title
			}
			section3Content {
				title
				content
			}
			section4Title
			section4Text {
				raw
			}
			section4Image {
				fluid {
					...GatsbyContentfulFluid
				}
				file {
					contentType
					url
				}
				title
			}
			section5Title
			section5Images {
				file {
					url
					contentType
				}
				title
				description
				fixed {
					...GatsbyContentfulFixed
				}
			}
			ctaTitle
			ctaSubtitle
			seoTitle
			seoDescription
			seoArticle
			seoImage {
				title
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
			}
		}
	}
`;

export default comparisonTemplate;
