import React from 'react';
import FeatureSnippetTwo from '../../global/FeatureSnippetTwo';
import SeparatorHR from '../../global/SeparatorHR';

const FeatureSnippetTwoHolder = ({ sectionImages }) => {
	return (
		<React.Fragment>
			{sectionImages.map(feature => (
				<>
					<FeatureSnippetTwo feature={feature} />
					<SeparatorHR margin="50" />
				</>
			))}
		</React.Fragment>
	);
};

export default FeatureSnippetTwoHolder;
