import React from 'react';
import * as S from './style';

const FeatureSnippetTwo = ({ feature }) => {
	const { file, title, description } = feature;
	return (
		<S.FeatureSnippetWrapper>
			<S.ImageContainer>
				<div className="image-wrapper">
					<img src={file.url} alt={title} width="125px" height="125px" />
				</div>
			</S.ImageContainer>
			<S.DescriptionContainer>
				<h3>{title}</h3>
				<p>{description}</p>
			</S.DescriptionContainer>
		</S.FeatureSnippetWrapper>
	);
};

export default FeatureSnippetTwo;
