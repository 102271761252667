import styled from 'styled-components';
import {
	setFontFamily,
	setFontSize,
	setFontWeight,
	mediaQuery,
} from '../../../../utils/helpers';

export const FeatureSnippetWrapper = styled.div`
	display: grid;
	grid-template-columns: 3fr 9fr;
	margin: 0 -15px;
	div {
		box-sizing: border-box;
	}
	${mediaQuery.tabletL`
        display: block;
    `}
`;

export const ImageContainer = styled.div`
	padding: 0 15px;
	.image-wrapper {
		display: flex;
		justify-content: center;
		height: 100px;

		img {
			object-fit: contain;
			max-width: 100%;
			height: auto;
		}
	}
`;

export const DescriptionContainer = styled.div`
	padding: 0 100px;
	font-family: ${setFontFamily.book};
	font-size: ${setFontSize.tiny};
	font-weight: ${setFontWeight.normal};
	font-stretch: normal;
	font-style: normal;
	line-height: 1.44;
	letter-spacing: -0.14px;
	text-align: left;

	h3 {
		font-family: ${setFontFamily.bold};
		font-size: ${setFontSize.large};
		font-weight: ${setFontWeight.extraBold};
		font-stretch: normal;
		font-style: normal;
		line-height: 1.39;
		letter-spacing: -0.29px;
		margin: 0;
	}
	p {
		margin: 0;
		margin-top: 20px;
	}
	${mediaQuery.laptopXL`
        font-size: ${setFontSize.mediumTiny};
    `}
	${mediaQuery.tabletL`
        font-size: ${setFontSize.veryTiny};
        h3{
            font-size: ${setFontSize.mediumSmall};
        }
    `}
    ${mediaQuery.mobileXL`
        padding: 0 15px;
        text-align: center;
        h3{
            font-size: ${setFontSize.verySmall};
            margin-top: 20px;
            margin-bottom: 10px;
        }
        p{
            margin: 0;
        }
    `}
`;
