import styled from 'styled-components';
import { mediaQuery } from '../../../../utils/helpers';

export const HeroWrapper = styled.div`
	& > div {
		padding-bottom: 107px;
	}
`;

export const SectionWrapper = styled.div`
	margin-top: 50px;
	background-color: ${props => props.bgColor && props.bgColor};
	.section-header {
		& > div {
			margin: 0;
		}
		h2 {
			line-height: 1.09;
		}
		p {
			line-height: 1.4;
		}
	}
	.cta-wrapper {
		margin-bottom: 20px;
	}
	${mediaQuery.mobileXL`
        margin: 0;
        margin-bottom: 20px;
		.disable-grid-mobile{
			display: block;
		}
		.cta-wrapper {
			margin-bottom: 0;
		}
    `}
`;

export const ContentWrapper = styled.div`
	padding: 70px 0;
	& > div {
		margin: 0;
	}
	p {
		margin: 0;
		line-height: 1.4;
	}

	.feature-header {
		& > div {
			margin: 0;
			h2 {
				padding: 30px 0;
			}
		}
	}
	.image-holder-wrapper > div > div {
		margin: 0;

		& > p:nth-of-type(1) {
			margin: 20px 0 10px 0;
		}
		${mediaQuery.tabletL`
			margin-bottom: 40px;
		`}
	}
	${mediaQuery.tabletL`
		.image-holder-wrapper > div > div:last-child {
			margin: 0;
		}
	`}

	.pt-20 {
		padding: 20px 0 0 0;
	}
	${mediaQuery.laptopXL`
		.pt-20{
			padding: 10px 0 0 0;
		}
	`}
	${mediaQuery.mobileXL`
		.feature-header {
			& > div{
				h2{
					padding: 0;
				}
			}
		}
	`}
`;

export const FeatureBoxWrapper = styled.div`
	padding-top: ${props => (props.padding ? `${props.padding}px` : '67px')};
	${mediaQuery.mobileXL`
		padding-top: 0;
	`}
`;
