import React from 'react';
import Img from 'gatsby-image';
import * as S from './style';

const FeatureBox = ({ image, content }) => {
	return (
		<S.Wrapper>
			<S.Left>
				{image.file.contentType === 'image/svg+xml' ? (
					<img
						src={image.file.url}
						style={{ width: '100%', maxHeight: '420px', borderRadius: '10px' }}
						className="imageClass"
						alt={image.title}
					/>
				) : (
					<Img
						fluid={image.fluid}
						style={{ width: '100%', maxHeight: '420px', borderRadius: '10px' }}
						className="imageClass"
						alt={image.title}
					/>
				)}
			</S.Left>
			<S.Right>
				<div className="contentWrapper">
					{content.map((item, index) => (
						<>
							<h4>{item.title}</h4>
							<p>{item.content}</p>
							{index !== content.length - 1 && <S.pBetween></S.pBetween>}
						</>
					))}
				</div>
			</S.Right>
		</S.Wrapper>
	);
};

export default FeatureBox;
